
// export const API_URL = 'https://jitsi-prod.illinoiscourtservices.net/VideoConferenceAuthentication';
// export const jitsi_URL = 'https://jitsi-prod.illinoiscourtservices.net';

//export const API_URL = 'http://localhost:8081/VideoConferenceAuthentication';
//export const jitsi_URL = 'http://localhost:3000';

export const API_URL = 'https://test.rpathos.com/VideoConferenceAuthentication';
export const jitsi_URL = 'https://test.rpathos.com';

//export const API_URL = 'https://rpathos.com/VideoConferenceAuthentication';
//export const jitsi_URL = 'https://rpathos.com';

export const jitsi_DOMAIN = 'conference.rpathos.com';

export const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser'
export const USER_FIRST_NAME_SESSION_ATTRIBUTE_NAME = 'firstName';
export const USER_LAST_NAME_SESSION_ATTRIBUTE_NAME = 'lastName';
export const TOKEN_SESSION_ATTRIBUTE_NAME = 'token'
export const USER_ROLE_SESSION_ATTRIBUTE_NAME = 'role';
export const TOKEN_LOGGED_IN_USER_COUNT = 'activeusercount';