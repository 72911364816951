import React, { Component } from 'react';
 import { MDBDataTable } from 'mdbreact';
import "@fortawesome/fontawesome-free/css/all.min.css";
import '../home/custommdb.css'
import  '../home/userlist.css';
import RoomService from './RoomService';
import moment from 'moment'

  class  roomhistory extends Component {      
    
    constructor(props) {
      super(props);
      this.state = {
        userListData : [],
        data : {
          columns: [],
          rows : []
        },
      }
      this.componentWillMount = this.componentWillMount.bind(this)
    }
  
    async componentWillMount(){
     
      // var a = moment('2020-10-15T05:20:33.000+0000'); 
      // var b = moment('2020-10-15T05:46:35.000+0000'); 
      // var duration =  moment.duration(b.diff(a));
      // var s = Math.floor(duration.asHours()) + moment.utc(b.diff(a)).format(":mm:ss");
      // console.log(s)
      //  console.log(duration.asHours())

      RoomService.getRoomHistory()
      .then(
          (response) => {

           //alert(JSON.stringify(response.data))
            
            for (var key in response.data) {

              if (response.data.hasOwnProperty(key)) {
               

                // alert(response.data[key].endtime)
                var starttime = moment(response.data[key].starttime).format('MM/DD/YYYY hh:mm A');
                if(response.data[key].endtime===undefined){
                  var endtime = ''; 
                }else{
                      endtime = moment(response.data[key].endtime).format('MM/DD/YYYY hh:mm A'); 
                }
                
              
                var a = moment(response.data[key].starttime); 
                var b = moment(response.data[key].endtime); 
              
                var duration =  moment.duration(b.diff(a));
                var totalduration = Math.floor(duration.asHours()) + moment.utc(b.diff(a)).format(":mm:ss");
              
                //console.log(totalduration)
               // alert(JSON.stringify(response.data[key].userRoles[0].role.name))
               
               this.state.userListData.push({username: response.data[key].username,meetingname:response.data[key].roomname,
                  starttime : starttime, endtime : endtime,joinedparticipants : response.data[key].maxroomsize,
                  duration:totalduration},)
              }

            }


            this.setState({
              data : {
                columns: [
                  {
                    label: "User Name",
                    field: "username",
                    width: 150
                  },
                  {
                    label: "Meeting Name",
                    field: "meetingname",
                    width: 200
                  },
                  {
                    label: "Start Time",
                    field: "starttime",
                    width: 100,
                    sort: "desc"
                  },
                  {
                    label: "End Time",
                    field: "endtime",
                    width: 100,
                    sort: "desc"
                  },
                  {
                    label: "Participant Joined",
                    field: "joinedparticipants",
                    width: 100,
                    align: "center"
                  },
                  {
                    label: "Duration",
                    field: "duration",
                    width: 150
                  },
                ],
              rows: this.state.userListData
            }
            })

      }
      ).catch(
          (error) => {
            //   console.log("Error :: " + error);
            //   if(error.response.status === 401) {
            //     window.location.href = "/logout";
            // }
          }
      )

    }

    render(){

      return ( 

       
        <form>
       <MDBDataTable responsive striped bordered hover 
       small data={this.state.data}/>
       </form>
     
   
       
       
       )

    } 
}
export default roomhistory